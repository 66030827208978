/*
包含 n 个接口请求函数的模块
每个函数返回 promise对象
*/

import ajax from "./ajax"
import {type} from "../../../redux/action";


const BASE = 'http://112.6.36.78:9080'


export const login1 = (username,password) => ajax(BASE + '/BXPRO/admin/login!login.action',{username,password})

