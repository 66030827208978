/**
 * 变量说明
 * commonUrl：后台地址 http/https://ip（域名）+port
 * baseUrl：前台地址 http/https://ip（域名）+port
 * unitName：市区县中文名称
 * bookName：文书前缀
 * unitNameEn：市区县英文名称
 * header：登录页标题
 * words1：左侧上标题
 * words2：左侧下标题
 * login：登陆后显示文字
 * lng：地区经度
 * lat：地区纬度
 */


// export const commonUrl = "http://172.26.103.203:8080";
// export const commonUrl = "http://172.26.96.146:8088";
// export const commonUrl = "http://172.25.78.57:8088";
export const videoCutPath = "/mnt/local/src/upload/cut/"; //视频截图地址 edit by ljh
// export const videoType = "isc"; //视频类型 edit by ljh
//export const videoCutPath = "d://"; //视频截图地址 edit by ljh
//本地使用勿删,前端连接后端时的ip请写在最后
// export const commonUrl = "http://localhost:8088";
// export const baseUrl = "http://localhost:3000"

//本地使用勿删,前端连接后端时的ip请写在最后
// export const commonUrl = "http://58.57.138.61:9100";
// export const baseUrl = "http://58.57.138.61:8090"
// export const unitName = "东营区"
// // export const unitName = "平原县"
// export const bookName = "东营"
// export const footName = "青岛世纪亿联信息科技有限公司"
// export const unitNameEn = "DongYing"
// export const header="智慧市场"
// export const words1 = "食品安全"
// export const words2 = "智慧监管平台"
// export const login = "欢迎"
// export const lng=118.58215//东营
// export const lat=37.44878
//
// export const videoType = ""
// export const commonUrl = "https://www.yiwifi1.com:8088";
// export const baseUrl = "http://www.yiwifi1.com:8090"
// export const unitName = "平邑县"
// export const bookName = "平邑"
// export const footName = "山东联合网络通信有限公司平邑分公司"
// export const unitNameEn = "PingYi"
// export const header="智慧市场"
// export const words1 = ""
// export const words2 = ""
// export const login = "欢迎"
// export const lng=117.631884
// export const lat=35.511519


// nginx配置了ssl证书的情况下，如果对接的视频流是http而不是https，将导致视频无法播放
export const videoType = "isc"; //视频类型 edit by ljh
export const commonUrl = "https://www.bxscjg.cn:9400"
export const baseUrl = "http://www.bxscjg.cn"
export const unitName = "博兴县"
export const bookName = "博兴"
export const footName = "中国移动通信集团山东有限公司博兴分公司"
export const unitNameEn = "BoXing"
export const header="智慧市场监管平台"
export const words1 = "食品安全"
export const words2 = "智慧监管平台"
export const login = "欢迎"
export const lng=118.145323//博兴
export const lat=37.148205


// nginx配置了ssl证书的情况下，如果对接的视频流是http而不是https，将导致视频无法播放
// export const videoType = "isc"; //视频类型 edit by ljh
// export const commonUrl = "http://58.58.36.197:9100"
// export const baseUrl = "http://58.58.36.197:6190"
//
// export const unitName = "市北区"
// export const bookName = "市北"
// export const footName = "山东桥联智能科技有限公司"
// export const unitNameEn = "ShiBei"
// export const header="智慧市场"
// export const words1 = "食品安全"
// export const words2 = "智慧监管平台"
// export const login = "欢迎"
// export const lng=118.145323//博兴
// export const lat=37.148205



