import React, { Component } from 'react';
import Header from './components/Header';
import './style/common.less';

class App extends Component {
  render() {
    return (
        <div>
            <Header/>
        </div>
    );
  }
}

export default App;
